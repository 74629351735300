<template>
  <div class="contato-holder">
    <div class="contato">
      <div class="contato-title">
        <h2>Contato</h2>
        <div class="contato-dados__top">
          <p>
            Rua Brigadeiro Tobias, 2204 - Jd. Jacy<br />
            Campo Grande/MS
            <br /><br />
            67 3027-7979 / 3022-7979<br />
            topnetms@gmail.com
            <br /><br />
            Suporte técnico via telefone:<br />
            67 99255-7919 (WhatsApp)<br />
            8h às 20h (dias úteis)
          </p>
          <div class="icons">
            <a href="https://www.facebook.com/topnetmsinternet" target="_blank"
              ><img :src="fb" alt=""
            /></a>
            <a href="https://instagram.com/topnet_ms" target="_blank"
              ><img :src="insta" alt=""
            /></a>
            <a
              href="https://api.whatsapp.com/send?phone=5567992557919"
              target="_blank"
              ><img :src="whats" alt=""
            /></a>
          </div>
        </div>
      </div>
      <form class="contato-form" @submit.prevent="onSubmit">
        <input type="text" v-model="form.name" placeholder="Nome" required />
        <input
          required
          v-model="form.phone"
          type="tel"
          v-mask="['(##)####-####', '(##)#####-####']"
          placeholder="Telefone"
          pattern=".{13,15}"
        />
        <input v-model="form.mail" type="email" placeholder="E-mail" required />
        <textarea
          v-model="form.message"
          placeholder="Mensagem"
          required
          maxlength="5000"
        ></textarea>
        <div class="enviar">
          <button :disabled="isSending" type="submit" value="Submit">
            Enviar
          </button>
        </div>
      </form>
      <div class="contato-dados__bottom">
        <p>
          Rua Brigadeiro Tobias, 2204 - Jd. Jacy<br />
          Campo Grande/MS
          <br /><br />
          67 3027-7979 / 3022-7979<br />
          topnetms@gmail.com
          <br /><br />
          Suporte técnico via telefone:<br />
          67 99255-7919 (WhatsApp)<br />
          8h às 20h (dias úteis)
        </p>
        <div class="icons">
          <a href="https://www.facebook.com/topnetmsinternet" target="_blank"
            ><img :src="fb" alt=""
          /></a>
          <a href="https://instagram.com/topnet_ms" target="_blank"
            ><img :src="insta" alt=""
          /></a>
          <a
            href="https://api.whatsapp.com/send?phone=5567992557919"
            target="_blank"
            ><img :src="whats" alt=""
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
const fb = new URL('../assets/icons/fb.png', import.meta.url).href;
const insta = new URL('../assets/icons/insta.png', import.meta.url).href;
const whats = new URL('../assets/icons/whats.png', import.meta.url).href;
export default {
  directives: { mask },
  data() {
    return {
      fb,
      insta,
      whats,
      isSending: false,
      form: {
        name: '',
        phone: '',
        mail: '',
        message: '',
      },
      preferences: [
        {
          title: 'Performance',
          description:
            'Bla bla serviços que podemos oferecer erviços que podemos oferecer erviços que podemos oferecer erviços que podemos oferecer serviços que podemos oferecer.',
          items: [{ label: 'Active', value: 'performance', isRequired: true }],
        },
      ],
    };
  },
  methods: {
    onAccept() {
      console.log('User has accepted all cookies');
    },
    onSavePreferences(preferences) {
      console.log(preferences); // ['performance', 'ga', 'newRelic']
    },
    resetForm() {
      this.form = {
        name: '',
        phone: '',
        mail: '',
        message: '',
      };
    },
    async onSubmit(event) {
      event.preventDefault();
      console.log(this.form);

      this.isSending = true;
      const res = await this.$axios
        .post('contato', {
          lido: '2',
          name: this.form.name,
          phone: this.form.phone,
          mail: this.form.mail,
          message: this.form.message,
        })
        .catch((e) => {
          console.error('Contato', e);
          this.$toast.warning(
            `Algo deu errado, por favor tente outra forma de contato.`
          );
          setTimeout(this.$toast.clear, 3000);
          setTimeout(() => {
            this.isSending = false;
          }, 2000);
        });
      console.log(res);

      if (res.data.id) {
        this.$toast.success(`Mensagem enviada com sucesso!`);
        setTimeout(this.$toast.clear, 3000);
        this.resetForm();
      }
      this.isSending = false;
    },
  },
};
</script>

<style lang="scss">
.cookie-comply {
  position: fixed !important;
  @media only screen and (min-width: 1024px) {
    grid-template-columns: minmax(0, 1fr) 300px !important;
  }

  &__actions {
    grid-auto-flow: dense;
    direction: rtl;
    @media only screen and (min-width: 1024px) {
      grid-template-columns: none !important;
    }
  }

  &__button:first-child {
    max-width: 2px;
    display: none;
  }
}
.contato-holder {
  width: 100%;
}

.contato {
  max-width: 1440px;
  margin: 4rem auto 0;
  background-color: #fff;

  color: #0b4d9b;
  display: flex;
  &-title {
    margin-left: 4rem;
    flex: 1;
    h2 {
      font-family: 'Red Hat Display Bold';
      font-size: var(--font-size--super-large);
      margin: 0;
    }
  }

  &-dados {
    &__bottom {
      display: none;
      font-size: 1.25rem;
      font-weight: bold;
    }
    &__top {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  &-form {
    flex: 1;
    margin: 4rem;
    input {
      font-family: 'Red Hat Display';
      background-color: #0b4d9b;
      height: 4rem;
      width: 100%;
      margin-bottom: 2rem;
      border: 0;
      font-size: var(--font-size--large);
      color: #fff;
      padding-left: 1rem;
      padding-right: 1rem;
      &::placeholder {
        color: #e7dddd;
      }
    }
    textarea {
      font-family: 'Red Hat Display';
      background-color: #0b4d9b;
      height: 10rem;
      width: 100%;
      margin-bottom: 2rem;
      border: 0;
      font-size: var(--font-size--large);
      color: #fff;
      padding-left: 1rem;
      padding-right: 1rem;

      &::placeholder {
        color: #e7dddd;
      }
    }
    .enviar {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 2rem;
      width: 100%;
      button {
        background-color: #0b4d9b;
        height: 4rem;
        width: 15rem;
        font-size: var(--font-size--large);
        color: #e7dddd;
        border: 0;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }

        button:disabled,
        &[disabled] {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }

  .icons {
    a {
      margin-right: 1rem;
      img {
        height: 40px;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    margin: 2rem;
    flex-direction: column;
    padding: 2rem;
    &-title {
      margin-left: 1rem;
    }

    &-dados {
      &__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0.5rem;
      }
      &__top {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    &-title {
      margin-left: 0.5rem;
      text-align: center;
      h2 {
        font-size: var(--font-size--super-large);
      }
    }
  }
  @media only screen and (max-width: 520px) {
    margin: 0.5rem;
    padding: 0.5rem;
    &-title {
      margin-left: 0.5rem;
      text-align: center;
      h2 {
        font-size: calc(var(--font-size--super-large) / 1.5);
      }
    }
    &-form {
      margin: 1rem;
      input {
        height: 4rem;

        font-size: 1.5rem;
      }
      textarea {
        height: 6rem;
        font-size: 1.5rem;
      }
      .enviar {
        button {
          width: 100%;
          height: 4rem;
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
