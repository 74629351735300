<template>
  <div class="empresa-holder">
    <div class="empresa">
      <h2>A EMPRESA</h2>
      <div class="content">
        Somos a <strong>TOPNET-MS</strong>, empresa de telecomunicações criada
        em 2014, para oferecer a melhor conexão aos seus assinantes. Atuantes em
        Campo Grande MS, trabalhamos com 100% fibra óptica onde garantimos a
        transmissão de dados com ultra velocidade e qualidade, atendemos com
        planos residenciais e corporativos.<br />A
        <strong>TOPNET-MS</strong> está em fase de expansão, ampliando nossa
        área de cobertura, investindo no aprimoramento dos seus serviços, com
        comprometimento profissional e ético.<br />
        <strong>A satisfação do CLIENTE é nossa PRIORIDADE!</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.empresa-holder {
  width: 100%;
}
.empresa {
  max-width: 1440px;
  margin: 4rem auto 8rem auto;
  padding: 0 2rem;
  h2 {
    margin: 0;
    text-align: center;
    color: #134490;
    font-family: 'Red Hat Display Black';
    font-size: var(--font-size--super-large);
  }
  .content {
    margin-top: 3.5rem;
    padding: 0 2rem;
    color: #5d5d5d;
    font-family: 'Red Hat Display';
    font-size: var(--font-size--large);
    line-height: 38px;
    text-align: justify;
    text-justify: inter-character;
    strong {
      font-family: 'Red Hat Display Bold';
    }
  }

  @media only screen and (max-width: 1280px) {
    // h2 {
    //   font-size: 4.5rem;
    // }
    // .content {
    //   margin-top: 3rem;
    //   font-size: 1.2rem;
    //   line-height: 1.8rem;
    // }
  }
  @media only screen and (max-width: 768px) {
    h2 {
      font-size: calc(var(--font-size--super-large) / 1.2);
    }
    .content {
      margin-top: 2rem;
      padding: 0 0.5rem;
      font-size: var(--font-size--default);
      line-height: 32px;
    }
  }
  @media only screen and (max-width: 520px) {
    // h2 {
    //   font-size: 2.5rem;
    // }
    // .content {
    //   margin-top: 2.5rem;
    //   font-size: 1rem;
    //   line-height: 1.5rem;
    // }
  }
}
</style>
