<template>
  <div class="destaque-holder">
    <div class="destaque">
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
      ></loading>

      <carousel
        v-if="Object.keys(destaque).length && !isLoading"
        :settings="settings"
        :breakpoints="breakpoints"
        :autoplay="5000"
        :wrap-around="true"
      >
        <slide v-for="slide in destaque" :key="slide.id">
          <div class="carousel__item">
            <a :href="slide.url" v-if="slide.url" target="_blank">
              <img
                :src="`${
                  windowWidth > 520
                    ? slide.img
                    : slide.img_mobile
                    ? slide.img_mobile
                    : slide.img
                }`"
                @error="placeholder"
              />
            </a>
            <img
              v-else
              :src="`${
                windowWidth > 520
                  ? slide.img
                  : slide.img_mobile
                  ? slide.img_mobile
                  : slide.img
              }`"
            />
          </div>
        </slide>

        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import Loading from 'vue3-loading-overlay';

export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    Navigation,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      destaque: {},
    };
  },
  setup() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        // 425: {
        //   itemsToShow: 1.1,
        //   snapAlign: 'center',
        // },
        // 1024: {
        //   itemsToShow: 1,
        //   snapAlign: 'start',
        // },
      },
    };
  },
  methods: {
    async fetchDestaque() {
      this.isLoading = true;
      const destaque = await this.$axios.get(`destaque/b`).catch((e) => {
        console.error('Destaque', e);
      });
      this.destaque = destaque.data;
      console.log(destaque);
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    placeholder(e) {
      e.target.src = require(`@/assets/logotopnet.png`);
    },
  },
  mounted() {
    this.fetchDestaque();
  },
  created() {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style lang="scss">
.destaque-holder {
  max-width: 100%;
  background: url(../assets/bg.png) no-repeat center bottom;
  background-size: cover;
}
.destaque {
  position: relative;
  min-height: 75px;
  // limita o tamanho do slider
  max-width: 1920px;
  margin: 0 auto;

  & > .vld-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.carousel__item {
  // min-height: 400px;
  width: 100%;
  height: auto;
  // background-color: #ffffff;
  color: #134590;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: flex;
    width: 100%;
    // margin: 5px 0;
  }
}

.carousel__slide {
  padding: 0;
}

.carousel__prev,
.carousel__next {
  background-color: #fff7;
  border-radius: 50%;
}
</style>
