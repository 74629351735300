<template>
  <div class="navbar-holder">
    <div class="navbar-top">
      <vue-navigation-bar
        :options="navbarOptions"
        @vnb-item-clicked="vnbItemClicked"
      />
      <span class="whats-link">
        <a
          href="https://api.whatsapp.com/send?phone=5567992557919"
          target="_blank"
        >
          <span class="whats-link-text"> Atendimento por Whatsapp </span>
          <img :src="whats" alt="" />
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import VueNavigationBar from 'vue-navigation-bar';

const brandImage = new URL('../assets/logotopnet.png', import.meta.url).href;
const whats = new URL('../assets/icons/whatsapp-logo.svg', import.meta.url)
  .href;

export default {
  components: {
    'vue-navigation-bar': VueNavigationBar,
  },
  data() {
    return {
      whats,
      navbarOptions: {
        elementId: 'main-navbar',
        isUsingVueRouter: true,
        mobileBreakpoint: 992,
        brandImagePath: './',
        brandImage,
        brandImageAltText: 'brand-image',
        collapseButtonOpenColor: '#661c23',
        collapseButtonCloseColor: '#661c23',
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: 'Main Navigation',
        tooltipAnimationType: 'shift-away',
        tooltipPlacement: 'bottom',
        menuOptionsLeft: [
          {
            isLinkAction: true,
            type: 'link',
            text: 'Empresa',
            path: { name: 'empresa' },
          },
          {
            isLinkAction: true,
            type: 'link',
            text: 'Cobertura',
            path: { name: 'cobertura' },
          },
          // {
          //   isLinkAction: true,
          //   type: 'link',
          //   text: 'Planos',
          //   path: { name: 'planos' },
          // },
          {
            isLinkAction: true,
            type: 'link',
            text: 'Contato',
            path: { name: 'contato' },
          },
          {
            isLinkAction: true,
            type: 'link',
            text: 'Dúvidas',
            path: { name: 'duvidas' },
            arrowColor: '#659CC8',
          },
        ],
        menuOptionsRight: [
          {
            type: 'button',
            text: '2ᵃ via boleto',
            path: '#',
            class: 'button-boleto',
          },
        ],
      },
    };
  },
  methods: {
    // getValueComponent(text) {
    //   switch (text) {
    //     case 'Empresa':
    //       return 'empresa';
    //     case 'Cobertura':
    //       return 'cobertura';
    //     case 'Planos':
    //       return 'planos';
    //     case 'Contato':
    //       return 'contato';
    //     case 'Contrato':
    //       return 'contrato';
    //     case 'Dúvidas':
    //       return 'duvidas';
    //     default:
    //       break;
    //   }
    // },
    async vnbItemClicked(text) {
      if (text === '2ᵃ via boleto') {
        window.open(
          'https://lojatopnetms.app.br/central_assinante_web/login',
          '_blank'
        );
      }
      // else {
      //   const component = await this.getValueComponent(text);
      //   const element = document.getElementById(component);
      //   element?.scrollIntoView({
      //     behavior: 'smooth',
      //   });
      // }
    },
  },
};
</script>

<style lang="scss">
@import 'vue-navigation-bar/dist/vue-navigation-bar.css';
.navbar-holder {
  width: 100%;
  background-color: #134590;
}
.navbar-top {
  width: 100%;
  background-color: #134590;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
.vnb {
  background-color: #134590 !important;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0.7rem 0.5rem !important;
  .button-boleto {
    background-color: #ff423d;
    background-image: linear-gradient(to right, #ff423d, #ff7f1c);
    color: #ffffff;
    font-size: var(--font-size--large);
    font-family: 'Red Hat Display Bold';
    padding: 1rem;
    text-transform: capitalize;
    @media (max-width: 1210px) {
      font-size: var(--font-size--default);
      padding: 0.6rem;
    }
  }
  &__brand-image-wrapper {
    &__link {
      &__image {
        max-height: 5.4rem;
        width: 10.4rem;

        @media (max-width: 1210px) {
          max-height: 3.4rem;
          max-width: 7.4rem;
        }

        @media (max-width: 520px) {
          max-width: 7.4rem;
        }
      }
    }
  }

  &__menu-options {
    &--left {
    }

    &--right {
    }

    &__option {
      &__link {
        color: #ffffff !important;
        text-transform: uppercase;
        font-family: 'Red Hat Display Bold';
        font-size: var(--font-size--large);
        padding: 1.2rem;

        @media (max-width: 1210px) {
          font-size: var(--font-size--default);
          padding: 0.6rem;
        }

        &:hover {
        }

        &__icon {
          svg {
          }

          &--left {
          }

          &--right {
          }
        }
      }

      &__arrow {
        &--hover {
        }
      }

      &__button {
        &__icon {
          svg {
          }

          &--left {
          }

          &--right {
          }
        }
      }

      &__spacer {
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:hover {
        }

        &__icon {
          svg {
          }

          &--left {
          }

          &--right {
          }
        }

        &__text-wrapper {
          &__text {
          }

          &__sub-text {
          }
        }
      }

      &__hr {
      }
    }
  }

  &__collapse-button {
    &:hover {
    }

    &__image {
      color: #ffffff;
      filter: brightness(0) invert(1);
    }
  }

  &__popup {
    &__top {
      &__image {
      }

      &__close-button {
        &:hover {
        }

        &__image {
        }
      }
    }

    &__bottom {
      padding: 0;
      &__custom-section {
        padding: 0;
      }

      &__menu-options {
        margin-top: 0;
        &__option {
          &:not(:last-child) {
          }

          &__link {
            &:hover {
            }

            &--no-highlight {
              &:hover {
              }
            }

            &__icon {
              svg {
              }

              &--left {
              }

              &--right {
              }
            }
          }
        }
      }

      &__sub-menu-options {
        &__option {
          &__link {
            &:hover {
            }

            &__sub-text {
            }
          }
        }
      }
    }
  }
}

.vnb-button {
  &:hover {
  }
}

// .link-holder {
//   max-width: 1440px;
//   margin: 0 auto;
//   background-color: green;
//   height: 100%;
//   position: relative;
// }

.whats-link {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(-90%, -50%);

  &-text {
    color: white;
    font-size: 11px;
    line-height: 12px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 4px 35px 4px 25px;
    border-radius: 10px;
    background-color: #25d366;
  }
  img {
    display: flex;
    width: 58px;
    height: 58px;
    position: relative;
    z-index: 2;
  }
  @media only screen and (max-width: 993px) {
    display: none;
  }
}
</style>
